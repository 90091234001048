<template>

<app-screen :centralised="true" :loading="is.generating">

	<div class="login-intro">
		Check your INBOX or 'OTHER' folder in the company email system for your pin code
	</div>

	<div class="login-pin" :class="{'is-errored': is.errored}">

		<div class="login-pin-digit" :class="{'is-active': current === 1, 'is-complete': current > 1}"></div>
		<div class="login-pin-digit" :class="{'is-active': current === 2, 'is-complete': current > 2}"></div>
		<div class="login-pin-digit" :class="{'is-active': current === 3, 'is-complete': current > 3}"></div>
		<div class="login-pin-digit" :class="{'is-active': current === 4, 'is-complete': current > 4}"></div>

	</div>

	<div class="login-pad" :class="{'is-loading': is.loading}">

		<div class="login-pad-key is-digit" v-on:click="onKey(1)">1</div>
		<div class="login-pad-key is-digit" v-on:click="onKey(2)">2</div>
		<div class="login-pad-key is-digit" v-on:click="onKey(3)">3</div>
		<div class="login-pad-key is-digit" v-on:click="onKey(4)">4</div>
		<div class="login-pad-key is-digit" v-on:click="onKey(5)">5</div>
		<div class="login-pad-key is-digit" v-on:click="onKey(6)">6</div>
		<div class="login-pad-key is-digit" v-on:click="onKey(7)">7</div>
		<div class="login-pad-key is-digit" v-on:click="onKey(8)">8</div>
		<div class="login-pad-key is-digit" v-on:click="onKey(9)">9</div>
		<div class="login-pad-key"></div>
		<div class="login-pad-key is-digit" v-on:click="onKey(0)">0</div>
		<div class="login-pad-key is-key" v-on:click="onKey('back')" :class="{'is-disabled': current === 1}"><app-icon name="backspace" /></div>

	</div>
	
</app-screen>

</template>

<script>

export default {

	data: function() {

		return {
			current: 1,
			code: [' ', ' ', ' ', ' '],
			is: {
				validating: false,
				errored: false,
				generating: false
			}
		}

	},

	created: function() {

		this.generate()

	},

	methods: {

		generate: function() {

			this.is.generating = true

			this.$api.request(this.$constants.api.verify.generate, {
				user: this.$route.query.user
			}).then(function() {

				this.is.generating = false

			}.bind(this), function() {

				this.$router.push({
					'name': 'Login'
				})

			}.bind(this))

		},

		validate: function() {

			this.is.validating = true

			this.$api.request(this.$constants.api.verify.validate, {
				user: this.$route.query.user,
				pin: this.code.join('')
			}).then(function(response) {

				this.$store.commit('session', response.session)

				if (response.session.team) {

					this.$gtag.customMap({
						'dimension1': 'teamKey',
						'dimension2': 'teamName'
					})

					this.$gtag.set({
						'teamKey': response.session.team,
						'teamName': this.$_.findWhere(this.$store.getters['teams'], {
							id: response.session.team
						}).name
					})
					
				}

				if (response.session.firsttime) {

					this.$router.push({
						name: 'Welcome'
					})

				} else {

					this.$router.push({
						name: 'Tasks'
					})

				}

			}.bind(this), function() {

				this.is.errored = true
				this.is.validating = false
				this.current = 1
				this.code = [' ', ' ', ' ', ' ']

			}.bind(this))

		},

		onKey: function(value) {

			this.is.errored = false

			if (value === 'back') {

				this.code[this.current - 1] = ' '

			} else {

				this.code[this.current - 1] = value

			}

			this.current = (value === 'back') ? this.current - 1 : this.current + 1

			if (this.current > 4) {

				this.validate()

			}

		}

	}

}

</script>

<style scoped>

.login-intro {
	font-size: 20px;
	line-height: 30px;
	color: #1D81D6;
	width: 100%;
	text-align: center;
}

.login-pin {
	display: flex;
	flex-direction: row;
	margin: 48px 0px;
}

.login-pin-digit {
	width: 24px;
	height: 24px;
	border: 4px solid rgba(38, 153, 251, 0.25);
	border-radius: 50%;
	margin: 0px 8px;
	transition: all 100ms linear;
}

.login-pin.is-errored .login-pin-digit {
	border: 4px solid rgba(251, 38, 38, 0.25);
}

.login-pin.is-errored .login-pin-digit.is-active {
	border-color: #fb2626;
}

.login-pin-digit.is-active {
	border-color: #1D81D6;
}

.login-pin-digit.is-complete {
	background-color: #1D81D6;
}

.login-pad {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 252px;
}

.login-pad.is-loading {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP///yaZ+9/v/dbr/ez2/Sub+yaZ+02r+5TM/He//MTi/UKm+2S2+5vP/HvA/Mfk/Uao+yma+2i4++n0/d3u/fX6/VWv++Hw/fP5/cDh/a7Y/NHp/fD3/QAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
}

.login-pad > * {
	transition: opacity 100ms linear;
}

.login-pad.is-loading > * {
	opacity: 0;
}

.login-pad-key {
	width: 64px;
	height: 64px;
	margin: 10px;
	font-weight: 700;
}

.login-pad-key.is-disabled {
	opacity: 0.25;
	pointer-events: none;
}

.login-pad-key.is-digit {
	background-color: #fff;
	border-radius: 50%;
	text-align: center;
	line-height: 64px;
	color: #1D81D6;
	font-size: 24px;
	font-weight: 400;
	transition: all 100ms linear;
	cursor: pointer;
}

.login-pad-key.is-key {
	color: #1D81D6;
	font-size: 40px;
	line-height: 64px;
	cursor: pointer;
	transition: all 100ms linear;
	text-align: center;
}

.login-pad-key.is-digit:active,
.login-pad-key.is-key:active {
	transform: scale(1.1);
}

</style>
