<template>

<div class="task" :class="{'is-completed': completed}" v-on:click="onClick">

	<div class="task-icon" v-if="!completed">

		<i class="fa fa-camera" v-if="task.type === $constants.task.photo"></i>
		<i class="fa fa-video" v-if="task.type === $constants.task.video"></i>
		<i class="fa fa-list-ol" v-if="task.type === $constants.task.multi"></i>
		<i class="fa fa-microphone" v-if="task.type === $constants.task.audio"></i>

	</div>

	<div class="task-icon" v-if="completed">

		<i class="fa fa-check"></i>

	</div>
	
	<div class="task-name">

		{{ task.name }}

	</div>

	<div class="task-share" v-if="completed && task.type !== $constants.task.multi">

		<i class="fa fa-share-square" v-on:click.stop="onShareClick"></i>

	</div>

	<div class="task-points">

		+{{ points }}

	</div>

	<div class="share" v-if="is.sharing">

		<div class="share-item is-facebook" v-on:click.stop="onShareItem('fb')"><i class="fa fa-facebook"></i></div>
		<div class="share-item is-twitter" v-on:click.stop="onShareItem('tw')"><i class="fa fa-twitter"></i></div>
		<div class="share-item is-pinterest" v-on:click.stop="onShareItem('pi')"><i class="fa fa-pinterest"></i></div>
		<div class="share-item is-email" v-on:click.stop="onShareItem('em')"><i class="fa fa-envelope"></i></div>

	</div>

</div>

</template>

<script>

export default {

	props: ['task'],

	data: function() {
	
		return {
			is: {
				sharing: false
			}
		}

	},

	created: function() {

		window.addEventListener('click', this.onCloseSharing.bind(this))

	},

	beforeDestroy: function() {

		window.removeEventListener('click', this.onCloseSharing.bind(this))

	},

	computed: {

		points: function() {

			if (this.saved) {

				var saved = this.$store.getters['session/tasks'][this.task.id]

				if (this.task.type === this.$constants.task.multi && saved.status === this.$constants.task.incomplete) {

					return Math.floor(saved.points / 2)

				} else {

					return saved.points

				}

			} else {

				return this.task.points

			}

		},

		completed: function() {

			return this.$_.contains(this.$store.getters['session/tasks/completed'], this.task.id)

		},

		saved: function() {

			return this.$_.contains(this.$_.keys(this.$store.getters['session/tasks']), this.task.id)

		}

	},

	methods: {

		onClick: function() {

			this.$router.push({
				name: 'Task',
				query: {
					task: this.task.id
				}
			})

		},

		onShareClick: function() {

			if (navigator.share) {

				navigator.share({
					title: 'Earthday',
					text: this.task.name,
					url: this.$store.getters['session/tasks'][this.task.id].asset
				})

			} else {

				this.is.sharing = true

			}

		},

		onShareItem: function(type) {

			var text = this.task.name
			var url = this.$store.getters['session/tasks'][this.task.id].asset

			if (type === 'tw') {

				window.open("https://twitter.com/share?url="+ encodeURIComponent(url)+"&text="+encodeURIComponent(text), '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600')

			} else if (type === 'fb') {

				window.open('http://facebook.com/sharer/sharer.php?u='+encodeURIComponent(url), '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0')

			} else if (type === 'pi') {

				window.open('https://pinterest.com/pin/create/button/?url='+encodeURIComponent(url)+'&media='+encodeURIComponent(url)+'&description='+encodeURIComponent(text), '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0')

			} else if (type === 'em') {

				window.open('mailto:?subject=Greenstar&body='+encodeURIComponent(text)+' '+encodeURIComponent(url), '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0')

			}

		},

		onCloseSharing: function() {

			this.is.sharing = false

		}

	}

}

</script>

<style scoped>

.task {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-bottom: 32px;
}

.task:last-child {
	margin-bottom: 0px;
}

.task-icon {
	flex-shrink: 0;
	width: 24px;
	line-height: 36px;
	color: #1D81D6;
	font-size: 16px;
}

.task-name {
	flex-grow: 1;
	line-height: 20x;
	padding: 8px 10px;
	color: #1D81D6;
	font-weight: bold;
	font-size: 16px;
}

.task.is-completed .task-name {
	color: #BCE0FD;
}

.task-share {
	flex-shrink: 0;
	width: 24px;
	line-height: 36px;
	text-align: right;
	color: #1D81D6;
	font-size: 24px;
	cursor: pointer;
	margin-left: 20px;
	margin-right: 20px;
}

.task-points {
	flex-shrink: 0;
	width: 48px;
	height: 36px;
	line-height: 34px;
	text-align: center;
	border: 1px solid #1D81D6;
	border-radius: 8px;
	color: #1D81D6;
	font-size: 16px;
}

.task.is-completed .task-points {
	background-color: #1D81D6;
	color: #fff;
	font-weight: bold;
}

.share {
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
	z-index: 1000;
	height: 64px;
	display: flex;
	flex-direction: row;
	background-color: #fff;
}

.share-item {
	height: 64px;
	width: 25%;
	text-align: center;
	line-height: 64px;
	color: #fff;
	cursor: pointer;
	font-size: 32px;
}

.share-item.is-facebook {
	background-color: #4267B2;	
}

.share-item.is-twitter {
	background-color: #1DA1F2;
}

.share-item.is-pinterest {
	background-color: #E60023;
}

.share-item.is-email {
	background-color: #333;
}


</style>
